import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Account - ProIP.info",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      title: "Sign in - ProIP.info",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import(/* webpackChunkName: "register" */ "../views/Register.vue"),
    meta: {
      title: "Sign up - ProIP.info",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
    meta: {
      title: "Profile - ProIP.info",
    },
  },
  {
    path: "/databases",
    name: "Databases",
    component: () => import(/* webpackChunkName: "databases" */ "../views/Databases.vue"),
    meta: {
      title: "Databases - ProIP.info",
    },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: () => import(/* webpackChunkName: "invoices" */ "../views/Invoices.vue"),
    meta: {
      title: "Invoices - ProIP.info",
    },
  },
  {
    path: "/invoice/:id",
    name: "InvoiceUpdate",
    props: true,
    component: () => import(/* webpackChunkName: "invoice-update" */ "../views/InvoiceForm.vue"),
    meta: {
      title: "Edit invoice - ProIP.info",
    },
  },
  {
    path: "/social",
    name: "Social",
    component: () => import(/* webpackChunkName: "social" */ "../views/Social.vue"),
    meta: {
      title: "Social - ProIP.info",
    },
  },
  {
    path: "/recovery",
    name: "Recovery",
    component: () => import(/* webpackChunkName: "recovery" */ "../views/Recovery.vue"),
    meta: {
      title: "Recovery password - ProIP.info",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(/* webpackChunkName: "forgot-password" */ "../views/ForgotPassword.vue"),
    meta: {
      title: "Recovery password - ProIP.info",
    },
  },
  {
    path: "/invoice-create",
    name: "InvoiceCreate",
    props: true,
    component: () => import(/* webpackChunkName: "invoice-create" */ "../views/InvoiceCreate.vue"),
    children: [
      {
        path: ":recommend",
        name: "InvoiceCreateRecommend",
        props: true,
        component: () =>
          import(/* webpackChunkName: "invoice-create" */ "../views/InvoiceCreate.vue"),
      },
    ],
    meta: {
      title: "Create invoice - ProIP.info",
    },
  },
  {
    path: "/checkout/:id",
    name: "Checkout",
    props: true,
    component: () => import(/* webpackChunkName: "checkout" */ "../views/Checkout.vue"),
    meta: {
      title: "Checkout - ProIP.info",
    },
  },
  {
    path: "/referral",
    name: "Referral",
    component: () => import(/* webpackChunkName: "referral" */ "../views/Referral.vue"),
    meta: {
      title: "Referral program - ProIP.info",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
